<template>
  <div>
    <head-layout :head-btn-options="headBtnOptions" :head-title="$t('cip.dc.zljkcj.title.name2')" @head-save="headSave('save')"
                 @head-save-back="headSave('back')" @head-cancel="headCancel"></head-layout>
    <div class="newZtFormBox">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="120px"
               style="background-color: white;margin-top: 10px;">
        <!-- <form-layout ref="formLayout" :dataForm="form" :group="group"></form-layout> -->
        <el-collapse v-model="activeName">
          <el-collapse-item name="1" :title="$t('cip.dc.QTemplate.field.field')">
            <template slot="title">
              <i class="avue-group__title avue-group__header" style="color: #999999 !important; font-style: normal;padding: 0 10px;"><i class="el-icon-document" style="font-size: 20px;padding: 0 10px;"></i> {{ $t("cip.qm.business.qm.batch.field.baseInfo") }}
              </i>
            </template>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.InterfaceCode')" prop="infCode">
                  <el-input v-model="form.infCode" autocomplete="off" class='new-form-input' disabled></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.InterfaceAddress')" prop="infUrl">
                  <el-input v-model="form.infUrl" :placeholder="$t('cip.dc.zljkcj.field.InterfaceAddress')" autocomplete="off"
                            class='new-form-input'></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.AccessMode')" prop="synType">
                  <el-select v-model="form.synType" :placeholder="$t('cip.dc.zljkcj.field.AccessMode')" class='new-form-input'>
                    <el-option :label="$t('cip.dc.zljkcj.field.increment')" :value="1"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.SourceSystem')" prop="sourceId">
                  <el-select v-model="form.sourceId" :placeholder="$t('cip.dc.zljkcj.field.SourceSystem')" class='new-form-input'>
                    <el-option v-for="(item, index) in idList" :key="index" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.Addsteptimestamp')" prop="stepTime">
                  <el-date-picker v-model="form.stepTime" :placeholder="$t('cip.dc.zljkcj.field.Addsteptimestamp')" class='new-form-input'
                                  style="width:100%" type="datetime" value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.Interfaceconfigurationinformation')">
                  <el-input v-model="form.config" :placeholder="$t('cip.dc.zljkcj.field.Interfaceconfigurationinformation')"
                            autocomplete="off" class='new-form-input' disabled></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.Endtimefield')" prop="endTimeField">
                  <el-input v-model="form.endTimeField" :placeholder="$t('cip.dc.zljkcj.field.Endtimefield')"
                            autocomplete="off" class='new-form-input'></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.Updatetimefield')" prop="tsField">
                  <el-input v-model="form.tsField" :placeholder="$t('cip.dc.zljkcj.field.Updatetimefield')" autocomplete="off"
                            class='new-form-input'></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.StepLengthdays')" prop="stepSize">
                  <el-input v-model="form.stepSize" :placeholder="$t('cip.dc.zljkcj.field.StepLengthdays')" autocomplete="off"
                            class='new-form-input'></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.ReturnStatusCode')">
                  <el-input v-model="form.resultCode" :placeholder="$t('cip.dc.zljkcj.field.ReturnStatusCode')"
                            autocomplete="off" class='new-form-input'></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.IncrementalTimeType')" prop="tsFieldType">
                  <el-select v-model="form.tsFieldType" :placeholder="$t('cip.dc.zljkcj.field.IncrementalTimeType')"
                             class='new-form-input'>
                    <el-option :label="$t('cip.dc.zljkcj.field.Timetype')" :value="1"></el-option>
                    <el-option :label="$t('cip.dc.zljkcj.field.Timestamptype')" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.InterfaceName')" prop="infName">
                  <el-input v-model="form.infName" :placeholder="$t('cip.dc.zljkcj.field.InterfaceName')" autocomplete="off"
                            class='new-form-input'></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.RequestMethod')" prop="requestMethod">
                  <el-select v-model="form.requestMethod" :placeholder="$t('cip.dc.zljkcj.field.RequestMethod')"
                             class='new-form-input'>
                    <el-option :value="1" label="get"></el-option>
                    <el-option :value="2" label="post"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.Token.field.Tokensourceid')" prop="tokenId">
                  <el-select v-model="form.tokenId" :placeholder="$t('cip.dc.Token.field.Tokensourceid')" class='new-form-input'>
                    <el-option v-for="(item,index) in tokenidList " :key="index" :label="item.infCode" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.Maximumtimestamp')" prop="deltaTime">
                  <el-date-picker v-model="form.deltaTime" :placeholder="$t('cip.dc.zljkcj.field.Maximumtimestamp')" class='new-form-input'
                                  style="width:100%" type="datetime" value-format="yyyy-MM-dd HH:mm:ss">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.dataType')">
                  <el-select v-model="form.dataType" :placeholder="$t('cip.dc.zljkcj.field.dataType')" class='new-form-input'>
                    <el-option :value="1" label="get"></el-option>
                    <el-option :value="2" label="post"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.StartTimeField')" prop="startTimeField">
                  <el-input v-model="form.startTimeField" :placeholder="$t('cip.dc.zljkcj.field.StartTimeField')"
                            autocomplete="off" class='new-form-input'></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.PrimarykeyField')">
                  <el-input v-model="form.primaryField" :placeholder="$t('cip.dc.zljkcj.field.PrimarykeyField')"
                            autocomplete="off" class='new-form-input'></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.TokenField')">
                  <el-input v-model="form.tokenField" :placeholder="$t('cip.dc.zljkcj.field.TokenField')" autocomplete="off"
                            class='new-form-input'></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.ReturnDataItem')" prop="resultData">
                  <el-input v-model="form.resultData" :placeholder="$t('cip.dc.zljkcj.field.ReturnDataItem')" autocomplete="off"
                            class='new-form-input'></el-input>
                </el-form-item>

              </el-col>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.ReturnDescription')" prop="resultMsg">
                  <el-input v-model="form.resultMsg" :placeholder="$t('cip.dc.zljkcj.field.ReturnDescription')"
                            autocomplete="off" class='new-form-input'></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-form-item :label="$t('cip.dc.zljkcj.field.TimeTypeFormat')" prop="formatType">
                  <el-input v-model="form.formatType" :placeholder="$t('cip.dc.zljkcj.field.IncrementalTimeTypeFormat')"
                            autocomplete="off" class='new-form-input'></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-collapse-item>
          <el-collapse-item name="2">
            <template slot="title">
              <i class="avue-group__title avue-group__header" style="color: #999999 !important; font-style: normal;padding: 0 10px;"><i class="el-icon-document" style="font-size: 20px;padding: 0 10px;"></i> {{ $t('cip.dc.zljkcj.field.RequestHeader') }}
              </i>
            </template>
            <el-row>
              <div style="margin-bottom: 20px;padding-left: 30px">
                <el-button icon="el-icon-circle-plus-outline" @click="headerListAddFn">{{
                    $t('cip.dc.zljkcj.field.AddRequestHeader')
                  }}
                </el-button>
              </div>
              <!-- <el-col v-for="(item, index) in form.headerList" style="margin-bottom: 20px!important;">
                      key: <el-input placeholder='key' class='new-form-input' v-model="item.key" autocomplete="off"></el-input>
                      value:<el-input placeholder='value' class='new-form-input' v-model="item.value"
                        autocomplete="off"></el-input>
                      <el-button type="danger" icon="el-icon-delete" circle @click="headerListDelFn(index)"></el-button>
                    </el-col> -->
            </el-row>


            <el-table
              :data="form.headerList"
              border
              style="width: 100%">
              <el-table-column
                label="key:"
                prop="key"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.key" autocomplete="off" class='new-form-input' placeholder='key'></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="value:"
                prop="value"
              >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.value" autocomplete="off" class='new-form-input' placeholder='key'></el-input>
                </template>
              </el-table-column>
              <el-table-column
                :label="this.$t('cip.cmn.btn.editBtn')"
                prop="address">
                <template slot-scope="scope">
                  <el-button circle icon="el-icon-delete" type="danger" @click="headerListDelFn(scope.row)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-collapse-item>
          <el-collapse-item :title="$t('cip.dc.zljkcj.field.RequestMethod')" name="3">
            <template slot="title">
              <i class="avue-group__title avue-group__header" style="color: #999999 !important; font-style: normal;padding: 0 10px;"><i class="el-icon-document" style="font-size: 20px;padding: 0 10px;"></i> {{ $t('cip.dc.zljkcj.field.RequestMethod') }}
              </i>
            </template>
            <el-row>
              <el-col span="8">
                <div style="margin-bottom: 20px;padding-left: 30px;display: flex;align-items: center">
                  <p style="width: 50px">{{ $t('cip.dc.zljkcj.field.parameter') }}</p>
                  <el-select v-model="form.timeLocation" class='new-form-input'>
                    <el-option :value="1" label="url"></el-option>
                    <el-option :label="$t('cip.dc.zljkcj.field.Requestor')" :value="2"></el-option>
                  </el-select>
                  <el-button v-if="form.timeLocation == 2" icon="el-icon-circle-plus-outline"
                             @click="headerconfigListAddFn">{{ $t('cip.dc.zljkcj.field.AddRequestHeader') }}
                  </el-button>
                </div>
              </el-col>
              <div v-if="form.timeLocation == 2">
                <el-row v-for="(item, index) in form.configList" :key="index" style='margin-bottom:10px'>
                  <el-col :span="6">
                    key:
                    <el-input v-model="item.key" autocomplete="off" class='new-form-input' placeholder='key'
                              style='width:150px'></el-input>
                  </el-col>
                  <el-col :span="6">
                    value:
                    <el-input v-model="item.value" autocomplete="off" class='new-form-input' placeholder='value'
                              style='width:150px'></el-input>
                  </el-col>
                  <el-col :span="12">
                    {{ $t('cip.dc.zljkcj.field.type') }}
                    <el-select v-model="item.type" style='width:200px!important;'>
                      <el-option :label="$t('cip.dc.zljkcj.field.constant')" :value="1"></el-option>
                      <el-option :value="2" label="json"></el-option>
                    </el-select>
                    <el-button circle icon="el-icon-delete" type="danger"
                               @click="headerconfigListDelFn(index)"></el-button>
                  </el-col>
                </el-row>
              </div>
            </el-row>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </div>
  </div>
</template>

<script>
import {datSoSel, itInCode, itInDetail, itInfUp} from "@/api/dataAcquisition";
import {listAll, listAll2} from "@/api/dataAcquisition/DataServices";

import FormLayout from "@/views/components/layout/form-layout";

export default {
  name: "dataCoonfigAdd",
  components: {FormLayout},
  computed: {
    group() {
      return [
        {
          label: this.$t("cip.qm.business.qm.batch.field.baseInfo"),
          prop: 'baseInfo',
          icon: 'el-icon-document',
          labelWidth: 120,
          column: [
            {
              label: this.$t("cip.dc.zljkcj.field.InterfaceCode"),
              prop: "infCode",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.InterfaceCode"),
                trigger: "submit"
              }]
            },

            {
              label: this.$t("cip.dc.zljkcj.field.InterfaceAddress"),
              prop: "infUrl",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.InterfaceAddress"),
                trigger: "submit"
              }]
            },


            {
              label: this.$t("cip.dc.zljkcj.field.AccessMode"),
              prop: "synType",
              dicData: [
                {
                  label: this.$t("cip.dc.zljkcj.field.increment"),
                  value: "1"
                },

              ],
              type: "select",
              slot: true,
              props: {
                label: "label",
                value: "value"
              },
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.AccessMode"),
                trigger: "click"
              }]
            },


            {
              label: this.$t("cip.dc.zljkcj.field.SourceSystem"),
              prop: "sourceId",
              dicData: [],
              type: "select",
              slot: true,
              props: {
                label: "name",
                value: "id"
              },
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.SourceSystem"),
                trigger: "click"
              }]
            },


            {
              label: this.$t("cip.dc.zljkcj.field.Addsteptimestamp"),
              prop: "stepTime",
              type: "datetime",
              search: true,
              format: "yyyy-MM-dd HH:mm:ss",
              valueFormat: "yyyy-MM-dd HH:mm:ss",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.Addsteptimestamp"),
                trigger: "submit"
              }]
            },


            {
              label: this.$t("cip.dc.zljkcj.field.Interfaceconfigurationinformation"),
              prop: "config",
              disabled: true

            },

            {
              label: this.$t("cip.dc.zljkcj.field.Endtimefield"),
              prop: "endTimeField",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.Endtimefield"),
                trigger: "submit"
              }]
            },


            {
              label: this.$t("cip.dc.zljkcj.field.Updatetimefield"),
              prop: "tsField",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.Updatetimefield"),
                trigger: "submit"
              }]
            },


            {
              label: this.$t("cip.dc.zljkcj.field.StepLengthdays"),
              prop: "stepSize",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.StepLengthdays"),
                trigger: "submit"
              }]
            },


            {
              label: this.$t("cip.dc.zljkcj.field.ReturnStatusCode"),
              prop: "resultCode",

            },


            {
              label: this.$t("cip.dc.zljkcj.field.IncrementalTimeType"),
              prop: "tsFieldType",
              // dicData: [
              //   {
              //     label: this.$t('cip.dc.zljkcj.field.Timetype'),
              //     value: "1"
              //   },
              //   {
              //     label: this.$t('cip.dc.zljkcj.field.Timestamptype'),
              //     value: "2"
              //   }
              // ],
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=timetype_timestamptype",
              type: "select",
              slot: true,
              props: {
                label: "label",
                value: "value"
              },
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.IncrementalTimeType"),
                trigger: "click"
              }]
            },


            {
              label: this.$t("cip.dc.zljkcj.field.InterfaceName"),
              prop: "infName",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.InterfaceName"),
                trigger: "submit"
              }]
            },


            {
              label: this.$t("cip.dc.zljkcj.field.RequestMethod"),
              prop: "requestMethod",
              // dicData: [
              //   {
              //     label: "get",
              //     value: "1"
              //   },
              //   {
              //     label: "post",
              //     value: "2"
              //   }
              // ],
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=Get_post",
              type: "select",
              slot: true,
              props: {
                label: "label",
                value: "value"
              },
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.RequestMethod"),
                trigger: "click"
              }]
            },


            {
              label: this.$t("cip.dc.Token.field.Tokensourceid"),
              prop: "tokenId",

            },


            {
              label: this.$t("cip.dc.zljkcj.field.Maximumtimestamp"),
              prop: "deltaTime",
              type: "datetime",
              search: true,
              format: "yyyy-MM-dd HH:mm:ss",
              valueFormat: "yyyy-MM-dd HH:mm:ss",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.Maximumtimestamp"),
                trigger: "submit"
              }]
            },


            {
              label: this.$t("cip.dc.zljkcj.field.dataType"),
              prop: "dataType",
              // dicData: [
              //   {
              //     label: "get",
              //     value: "1"
              //   },
              //   {
              //     label: "post",
              //     value: "2"
              //   }
              // ],
              dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=Get_post",
              type: "select",
              slot: true,
              props: {
                label: "label",
                value: "value"
              },

            },


            {
              label: this.$t("cip.dc.zljkcj.field.StartTimeField"),
              prop: "startTimeField",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.StartTimeField"),
                trigger: "submit"
              }]
            },


            {
              label: this.$t("cip.dc.zljkcj.field.PrimarykeyField"),
              prop: "primaryField",

            },

            {
              label: this.$t("cip.dc.zljkcj.field.TokenField"),
              prop: "tokenField",

            },


            {
              label: this.$t("cip.dc.zljkcj.field.ReturnDataItem"),
              prop: "resultData",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.ReturnDataItem"),
                trigger: "submit"
              }]
            },


            {
              label: this.$t("cip.dc.zljkcj.field.ReturnDescription"),
              prop: "resultMsg",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.ReturnDescription"),
                trigger: "submit"
              }]
            },


            {
              label: this.$t("cip.dc.zljkcj.field.TimeTypeFormat"),
              prop: "formatType",
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.zljkcj.field.TimeTypeFormat"),
                trigger: "submit"
              }]
            },


          ]
        }
      ]
    },
  },
  data() {
    return {
      idList: [],
      tokenidList: [],
      activeName: '1',
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: "",
        btnOptType: 'save',
        loading:false,
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: "",
          loading:false,
        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      rules: {
        infCode: [
          {required: true, message: this.$t('cip.dc.zljkcj.field.InterfaceCode'), trigger: 'change'}
        ],
        infName: [
          {required: true, message: this.$t('cip.dc.zljkcj.field.InterfaceName'), trigger: 'change'}
        ],
        infUrl: [
          {required: true, message: this.$t('cip.dc.zljkcj.field.InterfaceAddress'), trigger: 'change'}
        ],
        requestMethod: [
          {required: true, message: this.$t('cip.dc.zljkcj.field.RequestMethod'), trigger: 'change'}
        ],
        synType: [
          {required: true, message: this.$t('cip.dc.zljkcj.field.AccessMode'), trigger: 'change'}
        ],
        sourceId: [
          {required: true, message: this.$t('cip.dc.zljkcj.field.SourceSystem'), trigger: 'change'}
        ],
        deltaTime: [
          {required: true, message: this.$t('cip.dc.zljkcj.field.Maximumtimestamp'), trigger: 'change'}
        ],
        stepTime: [
          {required: true, message: this.$t('cip.dc.zljkcj.field.Addsteptimestamp'), trigger: 'change'}
        ],
        startTimeField: [
          {required: true, message: this.$t('cip.dc.zljkcj.field.startTime'), trigger: 'change'}
        ],
        endTimeField: [
          {required: true, message: this.$t('cip.dc.zljkcj.field.endTime'), trigger: 'change'}
        ],
        tsField: [
          {required: true, message: this.$t('cip.dc.zljkcj.field.Updatetime'), trigger: 'change'}
        ],
        stepSize: [
          {required: true, message: this.$t('cip.dc.zljkcj.field.StepLengthdays'), trigger: 'change'}
        ],
        resultData: [
          {required: true, message: this.$t('cip.dc.zljkcj.field.ReturnDataItem'), trigger: 'change'}
        ],
      },
      form: {
        "tokenId": '',
        "infCode": "",
        "infName": "",
        "url": "",
        "method": '',
        "type": '',
        "startTime": "",
        "endTime": "",
        "deltaTime": "",
        "stepTime": "",
        "step": '',
        "resultData": "",
        "resultCode": "",
        "resultMsg": "",
        "primaryField": "",
        "sourceId": '',
        "state": '',
        "config": "",
        "dbInfo": "",
        "tsField": "",
        "timeLocation": "",
        "tokenField": "",
        "formatType": '',
        "headerList": [],
        "configList": [],
        "dataType": ''
      },
      formLabelWidth: '120px',
      selDat: []
    }
  },
  created() {
    this.datSoSelFn()
    this.getAutoCodeFn()
    this.getItInDetail()
    this.getlistAll()
  },
  methods: {

    getlistAll() {
      listAll({}).then(res => {

        this.idList = res.data.data
        this.group[0].column.forEach(element => {
          if (element.prop == 'sourceId') {
            element.dicData = res.data.data
          }
        });
      })

      listAll2({}).then(res => {

        this.tokenidList = res.data.data
      })
    },
    getItInDetail() {
      if (this.$route.query.type !== 'edit') return
      itInDetail(this.$route.query.id).then(res => {
        this.form = res.data.data
      })
    },

    headSave(type) {

      this.headBtnOptions[0].loading = true
      this.headBtnOptions[1].loading = true
      // this.$refs.formLayout.$refs.form.validate((valid) => {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            itInfUp(this.form).then(() => {
              this.$message.success(this.$t('cip.cmn.msg.success.updataSuccess'));
              this.headBtnOptions[0].loading = false
              this.headBtnOptions[1].loading = false
              if (type == 'back') {
                this.headCancel()
              }
            })
          } else {
            itInfUp(this.form).then(() => {
              this.$message.success(this.$t('cip.cmn.msg.success.addSuccess'));
              this.headBtnOptions[0].loading = false
              this.headBtnOptions[1].loading = false
              if (type == 'back') {
                this.headCancel()
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    headerListAddFn() {
      this.form.headerList.push({key: '', value: ''})
    },
    headerListDelFn(e) {
      let index = ""
      this.form.headerList.forEach((element, idx) => {
        if (element == e) {
          index = idx
        }
      });
      this.form.headerList.splice(index, 1)
    },
    headerconfigListAddFn() {
      this.form.configList.push({key: '', value: '', type: null})
    },
    headerconfigListDelFn(index) {
      this.form.configList.splice(index, 1)
    },
    getAutoCodeFn() {
      if (this.$route.query.type == 'add') {
        itInCode().then((res) => {
          this.form.infCode = res.data.data;
          this.headerListAddFn()
        })
      }

    },
    datSoSelFn() {
      datSoSel().then((res) => {
        this.selDat = res.data.data;
      })
    },

  }
}
</script>

<style scoped></style>
